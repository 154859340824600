<template>
  <div class="nav_bar">
    <ul>
      <li
        :class="{ active: activeTab == item.key }"
        v-for="item in tabList"
        :key="item.key"
        @click="$emit('changeTab', item.key)"
      >
      <div> {{ item.name }}</div>
       <div>{{item.eg}}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    activeTab: {
      type: String,
      defalt: "fs",
      require: false,
    },
  },
  data() {
    return {
      tabList: [
        { name: "汽车扶手", key: "fs" ,eg:'Car armrest'},
        { name: "汽车脚托", key: "jt" ,eg:'Car Leg rest'},
        { name: "汽车腿托", key: "tt" ,eg:'Car foot supporting'},
        { name: "汽车头枕", key: "tz" ,eg:'Car headrest'},
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.nav_bar {
  width: 1200px;
  background: rgb(246, 246, 246);
  margin-top: 40px;
  ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 8px;
    li {
      width: 100%;
      text-align: center;
      list-style: none;
    //   height: 40px;
      font-size: 16px;
    //   line-height: 40px;
      cursor: pointer;
    }
    .active {
      background: #3894ff;
      color: #ffffff;
    }
  }
}
</style>
