<template>
  <div class="product_sever">
    <img-show></img-show>
    <nav-bar :activeTab="activeTab" @changeTab="changeTab"></nav-bar>
    <div class="main-box">
      <div class="sever_main"> 
        <product class="product_item" :activeTab="activeTab" v-for="(item,index) in currentProductList" :info="item"></product>
      </div>
     
    </div></div>
  </div>
</template>

<script>
import ImgShow from "@/components/ImgShow";
import product from './components/product'
import NavBar from './components/NavBar'
export default {
  data(){
    return {
      activeTab:"fs",
      currentProductList:[],
      productList:[
        {name:'tt',children:[
          {name:'GL8通用款腿托',src:'tt1',},
          {name:'艾力绅脚托腿拖',src:'tt2'},
          {name:'丰田塞纳腿托',src:'tt3'},
          {name:'福特途睿欧腿托',src:'tt4'},
          {name:'别克脚托',src:'tt5'}
        ]},
        {name:'jt',children:[
          {name:'GM8背式脚托',src:'jt1'},
          {name:'埃尔法/威尔法/雷克萨斯脚托',src:'jt2'},
          {name:'奥德赛腿拖脚托',src:'jt3'},
          {name:'别克Gl8脚托',src:'jt4'},
          {name:'大勇G10脚托',src:'jt5'},
          {name:'丰田塞纳脚托',src:'jt6'},
          {name:'冠道脚托',src:'jt7'},
          {name:'威霆V260/V250背式脚托',src:'jt8'},
          {name:'蔚来ES6脚托',src:'jt9'}
        ]},
        {name:'fs',children:[
          {name:'奥德赛/艾力绅扶手',src:'ff1'}
        ]},
         {name:'tz',children:[
           {name:'奥德赛汽车头枕',src:'tz1'}
        ]}
      ]
    }
  },
  created(){
    this.getCurrentList(this.activeTab)
  },
  methods:{
    changeTab(value){
      this.activeTab = value
      this.getCurrentList(value)
    },
    getCurrentList(value){
    this.productList.forEach(item=>{
        if(item.name == value){
          this.currentProductList = item.children
        }
      })
    }
  },
  components: { ImgShow ,product,NavBar},
};
</script>

<style lang="scss" scoped>
.product_sever {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  .main-box{
   display: flex;
   align-items: center;
   justify-content: center;
  }
  .sever_main {
    width: 1300px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    text-align: center;
    justify-content:flex-start;
    .product_item {
      // background: red;
      border:1px solid #eeeeee;
      width:300px;
      height: 300px;
      margin-bottom: 30px;
      margin-right: 10px;
      margin-left: 10px;
      
    }
  }
}
</style>
