<template>
  <div class="prodcut_l">
    <img :src="require(`@/assets/ProductSever/${activeTab}/${info.src}.jpg`)" alt="" />
    <h3>{{info.name}}</h3>
    <!-- <div>xxxxxxxxx</div>
    <div>xxxxxxxxx</div> -->
  </div>
</template>

<script>
export default {
  props:['info','activeTab']
};
</script>

<style lang="scss" scoped>
.prodcut_l {
  width: 300px;
  height: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h3{
    margin-bottom: 20px;
  }
  img {
    height: 80%;
    width: 100%;
  }
}
</style>
