<template>
  <div class="img_box">
    <img :src="currentSrc" alt="" style="width: 100%; height: 100%" />
    <div class="img_box_title">
      <div class="zh">{{production}}</div>
      <div class="en">{{eglish}}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgSrc: [
        { name: "/productSever", src: require("@/assets/ProductSever/Swiper.png"),production:'产品介绍',eg:'Product introduction' },
        { name: "/about", src: require("@/assets/ProductSever/Swiper2.png"),production:'关于我们',eg:'About'},
        { name: "/case", src: require("../assets/Case/swiper.png"),production:'应用案例',eg:'Case'}
      ],
      currentSrc: null,
      production:'',
      eglish:''
    };
  },
  created() {
    this.imgSrc.forEach((item) => {
      console.log(item);
      if (this.$route.path == item.name) {
        this.currentSrc = item.src;
        this.production = item.production
        this.eglish = item.eg
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.img_box {
  height: 200px;
  position: relative;
  width: 100%;
  .img_box_title {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 36px;
    color: #fff;
  }
}
</style>
